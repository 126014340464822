import { render, staticRenderFns } from "./ViewSection.vue?vue&type=template&id=51c0f4c7&scoped=true"
import script from "./ViewSection.vue?vue&type=script&lang=js"
export * from "./ViewSection.vue?vue&type=script&lang=js"
import style0 from "../../../public/modules/bootstrap/css/bootstrap.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../public/modules/fonts/font-awesome.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "../../../public/modules/fontawesome/css/all.min.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "./ViewSection.vue?vue&type=style&index=3&id=51c0f4c7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c0f4c7",
  null
  
)

export default component.exports