import { render, staticRenderFns } from "./ProjectView.vue?vue&type=template&id=698e1d54"
import script from "./ProjectView.vue?vue&type=script&lang=js"
export * from "./ProjectView.vue?vue&type=script&lang=js"
import style0 from "../../public/modules/bootstrap/css/bootstrap.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../public/modules/fontawesome/css/all.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./ProjectView.vue?vue&type=style&index=2&id=698e1d54&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports