import { render, staticRenderFns } from "./TeamSection.vue?vue&type=template&id=4c5496ac&scoped=true"
import script from "./TeamSection.vue?vue&type=script&lang=js"
export * from "./TeamSection.vue?vue&type=script&lang=js"
import style0 from "../../../public/modules/bootstrap/css/bootstrap.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../public/modules/fonts/font-awesome.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "../../../public/modules/fontawesome/css/all.min.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "./TeamSection.vue?vue&type=style&index=3&id=4c5496ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c5496ac",
  null
  
)

export default component.exports