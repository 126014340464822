import { render, staticRenderFns } from "./WelcomeSection.vue?vue&type=template&id=52e8cb08&scoped=true"
import script from "./WelcomeSection.vue?vue&type=script&lang=js"
export * from "./WelcomeSection.vue?vue&type=script&lang=js"
import style0 from "../../../public/modules/bootstrap/css/bootstrap.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../public/modules/fonts/font-awesome.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "../../../public/modules/fontawesome/css/all.min.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "./WelcomeSection.vue?vue&type=style&index=3&id=52e8cb08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e8cb08",
  null
  
)

export default component.exports